<template>
	<v-app>
		<wide-aside-view />
	</v-app>
</template>

<script>
export default {
	name: "WideAside",

	components: {
		WideAsideView: () =>
			import(
				/* webpackChunkName: "wide-aside" */
				"./View"
			)
	}
};
</script>
